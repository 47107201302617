<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		
		<div v-loading="loading">
			<el-card class="box-card order-list">
				<div slot="header" class="clearfix">
					<el-breadcrumb separator="/">
						<el-breadcrumb-item :to="{ path: '/member/order_list' }">我的订单</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/member/order_detail?order_id=' + orderId }">订单详情</el-breadcrumb-item>
						<el-breadcrumb-item>退款</el-breadcrumb-item>
					</el-breadcrumb>
				</div>

				<!--商品信息-->
				<div class="goods-list">
					<table>
						<tr>
							<td width="62.5%">商品</td>
							<td width="12.5%">数量</td>
							<td width="12.5%">金额</td>
						</tr>
					</table>
				</div>

				<div class="goods-list">
					<table>
						<tr>
							<td width="62.5%">
								<div class="goods-info">
									<div class="goods-info-left">
										<router-link :to="{ path: '/product-' + refundData.order_goods_info.goods_id + '.html' }" target="_blank">
											<img
												class="goods-img"
												:src="$img(refundData.order_goods_info.sku_image)"
												@error="refundData.order_goods_info.sku_image = defaultGoodsImage"
											/>
										</router-link>
									</div>
									<div class="goods-info-right">
										<router-link :to="{ path: '/product-' + refundData.order_goods_info.goods_id + '.html' }" target="_blank">
											<div class="goods-name">{{ refundData.order_goods_info.sku_name }}</div>
										</router-link>
									</div>
								</div>
							</td>
							<td width="12.5%" class="goods-num">{{ refundData.order_goods_info.num }}</td>
							<td width="12.5%" class="goods-money">{{ siteInfo.price_unit }}{{ refundData.order_goods_info.goods_money }}</td>
						</tr>
					</table>
				</div>
			</el-card>

			<!--售后类型 -->
			<div class="item-block">
				<div class="block-text">售后类型</div>

				<div class="pay-type-list">
					<div v-if="refundData.service_type.length == 1" class="pay-type-item" :class="serviceType == 1 ? 'active' : ''" @click="selectServiceType(1)">退款无需退货</div>
					<div v-if="refundData.service_type.length > 1">
						<div class="pay-type-item" :class="serviceType == 2 ? 'active' : ''" @click="selectServiceType(2)">退货退款</div>
						<div class="pay-type-item" :class="serviceType == 3 ? 'active' : ''" @click="selectServiceType(3)">我要换货</div>
						<div class="pay-type-item" :class="serviceType == 4 ? 'active' : ''" @click="selectServiceType(4)">我要维修</div>
						<div class="pay-type-item" :class="serviceType == 5 ? 'active' : ''" @click="selectServiceType(5)">退差价</div>
					</div>
					<div class="clear"></div>
				</div>
			</div>

			<!--退款填写-->
			<div class="item-block">
				<div class="block-text"></div>

				<el-form ref="form" label-width="120px" class="refund-form">
					<div v-if="serviceType == 1 || serviceType == 2 || serviceType == 5">
						<el-form-item label="退款原因" v-if="serviceType == 5">
							<el-select placeholder="请选择" v-model="serviceReason">
								<el-option v-for="(item, itemIndex) in refundData.difference_reason_type" :key="itemIndex" :label="item" :value="item"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="退款原因" v-else>
							<el-select placeholder="请选择" v-model="serviceReason">
								<el-option v-for="(item, itemIndex) in refundData.refund_reason_type" :key="itemIndex" :label="item" :value="item"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="退款金额">
							<el-col :span="11">
								<el-input :value="refundData.refund_money" v-model="refundData.refund_money" @blur="checkRefundMoney" style="width:150px">
									<template slot="append">澳元</template>
								</el-input>
							</el-col>
							<el-col :span="11">
								<div v-if="refundData.refund_delivery_money > 0">（含运费：{{refundData.refund_delivery_money}}澳币）</div>
							</el-col>
						</el-form-item>
					</div>
					
					<div v-if="serviceType == 3">
						<el-form-item label="换货原因">
							<el-select placeholder="请选择" v-model="serviceReason">
								<el-option v-for="(item, itemIndex) in refundData.exchange_reason_type" :key="itemIndex" :label="item" :value="item"></el-option>
							</el-select>
						</el-form-item>
					</div>

					<div v-if="serviceType == 4">
						<el-form-item label="维修原因">
							<el-select placeholder="请选择" v-model="serviceReason">
								<el-option v-for="(item, itemIndex) in refundData.repair_reason_type" :key="itemIndex" :label="item" :value="item"></el-option>
							</el-select>
						</el-form-item>
					</div>

					<el-form-item label="补充描述和凭证">
						<el-input maxlength="140" show-word-limit resize="none" rows="5" placeholder="请输入补充描述（选填）" type="textarea" v-model="serviceRemark"></el-input>
					</el-form-item>

					<div class="ns-ser-form">
						<div class="img-list-item common mb_10" v-for="(item,index) in imgList" :key="index">
							<video controls="controls" :src="item.pic_path" v-if="item.file_ext == 'mp4'"> 您的浏览器不支持视频播放</video>
							<el-image :preview-src-list="fileLists" :src="item.pic_path" fit="contain" v-else></el-image>
							<i class="del-img" @click="forkImage(index)"  v-if='isShowImg==true'></i>
						</div>
						<div class="upload-list-item" v-if="!hide">
							<el-upload
								ref="upload"
								:class="{ ishide: hide }"
								:action="uploadActionUrl"
								:show-file-list="false"
								:on-success="
									(file) => {
										return handleSuccess(file);
									}
								"
								:on-exceed="handleExceed"
								:on-progress="uploadVideoProcess"
							>
								<i class="el-icon-plus"></i>
							</el-upload>
							<el-progress v-show="dialogVisible" :percentage="videoUploadPercent"></el-progress>
							<span>共6张，还能上传{{ imgList.length ? 6 - imgList.length : 6 }}张</span>
						</div>
					</div>
				</el-form>
			</div>

			<div class="item-block">
				<div class="order-submit"><el-button type="primary" class="el-button--primary" @click="submit">提交</el-button></div>
				<div class="clear"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { refundData, refund, detail, delivery } from '@/api/order/service';
import Config from '@/utils/config';
import { mapGetters } from 'vuex';

export default {
	name: 'service',
	components: {},
	data: () => {
		return {
			orderGoodsServiceId: '',
			orderGoodsId: '',
			orderId: '',
			serviceType: 2,
			serviceReason: '',
			serviceRemark: '',
			isIphoneX: false,
			max_refund_money: 0,
			refundData: {
				service_type: [],
				order_goods_info: {
					sku_image: ''
				}
			},
			isSub: false,
			isShowImg: true,
			videoUploadPercent:0,
			show_type: 0, //退款状态 1-待退款 2-已退款
			detail: {
				refund_action: []
			},
			imgList: [],
			fileLists: [],
			uploadActionUrl: Config.baseUrl + '/api/upload/serviceimg',
			dialogImageUrl: '',
			dialogVisible: false,
			hide: '',
			loading: true,
			yes: true
		};
	},
	created() {
		if (this.$route.query.order_goods_id) this.orderGoodsId = this.$route.query.order_goods_id;
		if (this.$route.query.order_goods_service_id) this.orderGoodsServiceId = this.$route.query.order_goods_service_id;
		if (this.$route.query.order_id) this.orderId = this.$route.query.order_id;
		this.getRefundData();
	},
	computed: {
		...mapGetters(['defaultGoodsImage', "siteInfo"])
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
	methods: {
		handleSuccess(file) {
			if (file.data['file_ext'] != 'mp4') {
				this.fileLists.push(file.data['pic_path'])
			}
			this.imgList.push(file.data);
			this.dialogVisible = false;
			this.videoUploadPercent = 0;
			if (this.imgList.length >= 6) {
				this.hide = true;
			}
		},
		
		handleExceed(file, fileList) {
			// 图片数量大于6
			this.$message.warning('上传图片最大数量为6张');
		},
		//进度条
		uploadVideoProcess(event, file, fileList) {
			this.dialogVisible = true;
			this.videoUploadPercent = Math.floor(event.percent);
			console.log(this.videoUploadPercent)
		},

		beforeUpload(file) {
			this.dialogVisible = true;
			if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb','image/jpeg','image/PNG','image/gif'].indexOf(file.type) == -1) {
                this.$message.error('请上传正确的视频/图片格式');
                return false;
            }
		},
		//删除视频/图片
		forkImage(index) {
			var data = this.imgList.splice(index, 1);
			this.$emit("delFile", this.imgList);
			for (var k = 0; k < this.imgList.length; k++) {
				if (this.imgList[k]['file_ext'] != 'mp4') {
					this.fileLists.push(this.imgList[k]['pic_path'])
				}
			}
			if(this.imgList.length < 6){
				this.hide = true;
			}else{
				this.hide = false;
			}
		},
		/**
		 * 选择退款方式
		 * @param {Object} type
		 */
		selectServiceType(type) {
			this.serviceType = type;
		},
		getRefundData() {
			if (this.orderGoodsServiceId) {
				refundData({ order_goods_service_id: this.orderGoodsServiceId }).then(res => {
					const { code, message, data } = res;
					if (code >= 0) {
						if (data.order_goods_info.sku_spec_format) {
							try {
								data.order_goods_info.sku_spec_format = JSON.parse(res.data.order_goods_info.sku_spec_format);
							} catch (e) {
								data.order_goods_info.sku_spec_format = res.data.order_goods_info.sku_spec_format;
							}
						} else {
							data.order_goods_info.sku_spec_format = [];
						}
						this.serviceType = data.order_goods_info.service_type;
						this.serviceReason = data.order_goods_info.service_reason;
						this.serviceRemark = data.order_goods_info.service_remark;
						this.imgList = data.order_goods_info.attachment;
						this.orderGoodsId = data.order_goods_info.order_goods_id;
						this.max_refund_money = data.refund_money;
						if (this.imgList.length >= 6) {
							this.hide = true;
						} else {
							this.hide = false;
						}
					
						this.fileLists = [];
						for (var k = 0; k < this.imgList.length; k++) {
							if (this.imgList[k]['file_ext'] != 'mp4') {
								this.fileLists.push(this.imgList[k]['pic_path'])
							}
						}
						this.refundData = data;
					} else {
						this.$message({
							message: '未获取到该订单项退款信息！',
							type: 'warning',
							duration: 2000,
							onClose: () => {
								this.$router.push({ path: '/member/activist' });
							}
						});
					}
					this.loading = false;
				}).catch(err => {
					this.loading = false;
					this.$message.error({
						message: err.message,
						duration: 2000,
						onClose: () => {
							this.$router.push({ path: '/member/activist' });
						}
					});
				});
			} else {
				refundData({ order_goods_id: this.orderGoodsId }).then(res => {
					const { code, message, data } = res;
					if (code >= 0) {
						this.max_refund_money = data.refund_money;
						this.refundData = data;
					} else {
						this.$message({
							message: '未获取到该订单项退款信息！',
							type: 'warning',
							duration: 2000,
							onClose: () => {
								this.$router.push({ path: '/member/activist' });
							}
						});
					}
					this.loading = false;
				}).catch(err => {
					this.loading = false;
					this.$message.error({
						message: err.message,
						duration: 2000,
						onClose: () => {
							this.$router.push({ path: '/member/activist' });
						}
					});
				});
			}
		},
		submit() {
			if (this.verify()) {
				if (this.isSub) return;
				this.isSub = true;

				let submit_data = {
					order_goods_service_id: this.orderGoodsServiceId,
					order_goods_id: this.orderGoodsId,
					service_type: this.serviceType,
					service_reason: this.serviceReason,
					service_remark: this.serviceRemark,
					apply_money: this.refundData.refund_money,
					attachment: JSON.stringify(this.imgList)
				};

				refund(submit_data).then(res => {
					const { code, message, data } = res;
					if (code >= 0) {
						this.$message({
							message: '申请成功！',
							type: 'success',
							duration: 2000,
							onClose: () => {
								this.$router.push({ path: '/member/activist' });
							}
						});
					} else {
						this.isSub = false;
						this.$message({ message: message, type: 'warning' });
					}
				}).catch(err => {
					this.$message.error({
						message: err.message,
						duration: 2000,
						onClose: () => {
							this.$router.push({ path: '/member/activist' });
						}
					});
				});
			}
		},
		verify() {
			if (this.serviceReason == '') {
				this.$message({ message: '请选择售后原因', type: 'warning' });
				return false;
			}
			if (this.serviceRemark == '') {
				this.$message({ message: '请填写补充描述', type: 'warning' });
				return false;
			}
			if (this.imgList.length == 0) {
				this.$message({ message: '至少上传一张图片凭证', type: 'warning' });
				return false;
			}
			return true;
		},
		checkRefundMoney(){
			if (this.refundData.refund_money > this.max_refund_money) {
				this.refundData.refund_money = this.max_refund_money
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
	box-shadow: unset;
}

.el-card {
	border: 0;
}
.clear {
	clear: both;
}
.item-block {
	padding: 0 15px 1px;
	margin: 10px 0;
	border-radius: 0;
	border: none;
	background: #ffffff;
	.block-text {
		border-color: #eeeeee;
		color: $ns-text-color-black;
		padding: 7px 0;
		border-bottom: 1px;
	}
}
.refund-form {
	width: 450px;
	.el-select {
		width: 100%;
	}
}
.order-submit {
	padding: 10px;
	margin-left: 120px;
}
.goods-list {
	padding: 15px 0;
	table {
		width: 100%;
	}
	.goods-info-left {
		width: 60px;
		height: 60px;
		float: left;
		.goods-img {
			width: 60px;
			height: 60px;
		}
	}
	.goods-info-right {
		float: left;
		height: 60px;
		margin-left: 10px;
		color: $base-color;
		width: 80%;
		.goods-name {
			line-height: 20px;
			padding-top: 10px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
		.goods-spec {
			color: #999;
		}
	}
}
.pay-type-list {
	padding: 20px 0;
}
.pay-type-item {
	display: inline-block;
	border: 2px solid #eeeeee;
	padding: 5px 20px;
	margin-right: 20px;
	cursor: pointer;
}
.pay-type-item.active {
	border-color: $base-color;
}
.status-wrap {
	color: #999;
}
.media-left {
	float: left;
}
.media-right {
	float: right;
	i.rotate {
		transform: rotate(180deg);
		transition: 0.3s;
	}
}
.action-box {
	padding: 10px 0;
}
.action-way {
	float: left;
	color: #999;
}
.head .time {
	float: right;
	color: #999;
}
.record-item {
	margin-bottom: 10px;
}
.order-statistics {
	float: left;
	padding: 10px;
	// color: #999;
}
.el-textarea .el-input__count {
	line-height: 20px;
}
</style>
<style lang="scss">
.refund-form {
	.ns-ser-form {
		margin-left: 120px;
		display: inline-flex;
		.el-upload--text {
			background-color:#fbfdff;
			border:1px dashed #c0ccda;
			border-radius:6px;
			box-sizing:border-box;
			width:148px;
			height:148px;
			cursor:pointer;
			line-height:146px;
		}
		.el-upload--text,
		.el-upload-list--picture-card .el-upload-list__item {
			width: 90px;
			height: 90px;
			line-height: 90px;
			position: relative;
		}
		.el-upload-list--picture-card .el-upload-list__item-thumbnail {
			width: 100%;
			height: auto;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		.el-upload-list__item.is-success .el-upload-list__item-status-label {
			display: none;
		}
		.ishide .el-upload--text {
			display: none;
		}
		.el-dialog {
			.el-dialog__body {
				text-align: center;
			}
		}
		::v-deep.el-upload-list {
			display: none;
		}
		.el-upload-video {
			width: 149px;
			height: 149px;
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}
		.el-upload-video-i {
			font-size: 20px;
			font-weight: bold;
			padding-top: 43px;
			color: #8c939d;
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
		}
		.upload-list-item {
			position: relative;
			margin: auto;
			width: 150px;
			height: 120px;
			margin-right: 10px;
			overflow: hidden;
			background-color: #fff;
			display: inline-block;
		}
		//视频
		.img-list-item {
			position: relative;
			margin: auto;
			width: 120px;
			height: 120px;
			margin-right: 10px;
			overflow: hidden;
			background-color: #fff;
			border: 1px solid #c0ccda;
			border-radius: 6px;
			box-sizing: border-box;
			display: inline-block;
			video, .el-image {
				width: 120px;
				height: 120px;
				line-height: 120px;
				position: relative;
				img {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
			}
			.el-image-viewer__canvas {
				img {
					width: 50%;
					top: 10%;
  					height: auto;
				}
			}
		}

		.img-list-item i.del-img {
			width: 20px;
			height: 20px;
			display: inline-block;
			background: rgba(0, 0, 0, .6);
			background-image: url(../../assets/images/close.png);
			background-size: 18px;
			background-repeat: no-repeat;
			background-position: 50%;
			position: absolute;
			top: 0;
			right: 0px;
		}
	}
}
</style>
